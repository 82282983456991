import { gql } from '@apollo/client';

export const CREATE = gql`
  mutation insertMember($objects: [members_insert_input!]!) {
    insert_members(objects: $objects) {
      id
    }
  }
`;

export const GET_MEMBERS = gql`
  query GetMembers {
    members {
      id
      category
      subcategory
      keywords
      institution_id
      member_type_id
      profile_id
      status
      profile {
        id
        names
        last_name
        phone
        id_number
        image
      }
    }
  }
`;

export const DETAIL = gql`
  query GetMember($id: String!) {
    getMember(id: $id) {
      id
      category
      subcategory
      institution_id
      member_type_id
      profile_id
      profile {
        id
        last_name
        names
        id_number
      }
      attrs
    }
  }
`;

export const EDIT = gql`
  mutation EditMember($id: String!, $changes: EditMemberInput!) {
    editMember(id: $id, changes: $changes) {
      id
      status
    }
  }
`;

export const LAST_PAY = gql`
  query GetLastPaidMonth($memberId: String!, $institutionId: String!) {
    memberLastPay(memberId: $memberId, institutionId: $institutionId) {
      year
      month
    }
  }
`;