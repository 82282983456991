import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import { EDIT } from 'src/graphql/members';

// Definimos el hook para crear el Member
export const useEditMember = () => {
  const [editMember, { data, loading, error }] = useMutation(EDIT, {
    onCompleted: (dataMember) => {
      console.log('Member updated successfully:', dataMember);
      enqueueSnackbar('Actualizado correctamente!');
    },
    onError: (err) => {
      console.error('Error updating member:', err);
    },
  });

  const handleEditMember = (id, changes) => {
    editMember({
      variables: {
        id,
        changes
      },
    });
  };

  return {
    handleEditMember,
    data,
    loading,
    error,
  };
};