import { useSuspenseQuery } from '@apollo/client';
import { LAST_PAY } from '../../graphql/members'; // Importa el query que definimos anteriormente

export const useGetMemberLastPay = ({ memberId, institutionId }) => {
  const { loading, error, data } = useSuspenseQuery(LAST_PAY, {
    variables: {
      memberId,
      institutionId
    }
  });

  console.log({ data })

  return {
    loading,
    error,
    lastPay: data.memberLastPay.length ? data.memberLastPay[0] : null,
  };
};