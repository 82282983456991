import { useMutation } from '@apollo/client';
import { CREATE } from 'src/graphql/memberPayment';

// Definimos el hook para crear el Member
export const useCreateMemberPayment = () => {
  const [createMemberPayment, { data, loading, error }] = useMutation(CREATE, {
    onCompleted: (dataMember) => {
      console.log('MemberPayment created successfully:', dataMember);
    },
    onError: (err) => {
      console.error('Error creating memberPayment:', err);
    },
  });

  const handleCreateMemberPayment = (objects) => {
    createMemberPayment({
      variables: {
        objects
      },
    });
  };

  return {
    handleCreateMemberPayment,
    data,
    loading,
    error,
  };
};