import { gql } from '@apollo/client';

export const CREATE = gql`
  mutation CreateMemberPayment($objects: [MemberPaymentInputDto!]!) {
    insert_member_payments(objects: $objects) {
      year
      month
      member_id
      institution_id
      is_paid
    }
  }
`;