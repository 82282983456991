import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import { CREATE } from 'src/graphql/members';

export const useCreateMember = () => {
  const [createMember, { data, loading, error }] = useMutation(CREATE, {
    onCompleted: (dataMember) => {
      console.log('Member created successfully:', dataMember);
      enqueueSnackbar('Creado correctamente!');
    },
    onError: (err) => {
      console.error('Error creating member:', err);
    },
  });

  const handleCreateMember = (object) => {
    createMember({
      variables: {
        objects: [object],
      },
    });
  };

  return {
    handleCreateMember,
    data,
    loading,
    error,
  };
};